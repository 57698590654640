.banner {
  position: relative;
  overflow-y: hidden;

  .banner-overlay {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    .banner-logo {
      height: 90px;
      @include media-breakpoint-up(sm) {
        height: 150px;
      }
    }
    .banner-title {
      a {
        color: white;
        transition: color 500ms;
        &:hover {
          color: #E3B4C3;
          text-decoration: none;
        }
      }
      text-shadow: 1px 1px 1px rgb(0 0 0 / 25%);
      font-size: 1.75rem;
      @include media-breakpoint-up(sm) {
        font-size: 2.5rem;
      }
    }
    .banner-slogan {
      font-size: 1.1rem;
      color: #aaa;
      text-shadow: 1px 1px 1px rgb(0 0 0 / 25%);
      margin-bottom: 0;
    }
  }
  .banner-background {
    position: absolute;
    background-image: url(../img/banner.jpg);
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
}
