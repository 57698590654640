$primary: #680536;
$active-color: $primary;

$headings-color: #680536;
$link-color: #C33B6D;

$content-bg: #F5F5F5;
$body-bg: #273f57;
$content-title-border-color: #e6e6e6;

$navbar-background-color: #470023;
$navbar-active-color: rgba(255,255,255,1);
$navbar-inactive-color: rgba(255,255,255,0.6);
$navbar-separator-color: rgba(255,255,255,0.3);

$footer-bg-color: #470023;

@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');
$font-family-base: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
h1, h2 {
  font-family: "Ubuntu",sans-serif;
}

@import "global-style/globalstyle";

@import "~@shopcrate/shopcrate-framework/lib/index";

@import "~react-loading-skeleton/dist/skeleton.css";

@import "banner";
@import "content";
@import "course";
@import "footer";
@import "jumbotron";
@import "markdown";
@import "navbar";
@import "price";
