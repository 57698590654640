$small-display-size-width: 575;
$small-display-size-height: 600;
$mobile-height-sticky-navbar: 575;

$navbar-brand-color: $active-color !default;
$navbar-active-color: $active-color !default;

.navbar-only {
	box-shadow: 0 0 5px black;

	@media (max-width: #{$small-display-size-width}px), (max-height: #{$small-display-size-height}px) {
		padding-top: 5px;
		padding-bottom: 5px;
	}

	@media (max-height: #{$mobile-height-sticky-navbar}px) {
		position: absolute;
		top: 0;
	}
}

.navbar-main {
	@extend .navbar-only;
	margin-top: 26px;

	@media (max-width: #{$small-display-size-width}px), (max-height: #{$small-display-size-height}px) {
		margin-top: 0;
	}
}

.navbar-second {
	background-color: #F8F8F8 !important;
	z-index: 1040;
	border-bottom: solid lightgray 1px;
	padding-top: 2px;
	padding-bottom: 2px;

	@media (max-width: #{$small-display-size-width}px), (max-height: #{$small-display-size-height}px) {
		display: none;
	}

	.navbar-nav > li > a {
		padding: 0;
		font-size: 15px;
	}
}

.navbar-brand-desktop {
	display: block;

	@media (max-width: #{$small-display-size-width}px), (max-height: #{$small-display-size-height}px) {
		display: none;
	}
}
.navbar-brand-small {
	display: none;

	@media (max-width: #{$small-display-size-width}px), (max-height: #{$small-display-size-height}px) {
		display: block;
	}
}
.navbar-brand {
	color: $navbar-brand-color !important;
	font-weight: bold;
}

.navbar-nav > .active > .nav-link {
	font-weight: bold;
	color: $navbar-active-color !important;
}

.dropdown-item.active,
.dropdown-item:active {
	color: white;
	background-color: $navbar-active-color !important;
}

.navbar-toggler {
	border: none !important;
}

$page-spacer-height: 152px !default;
$page-spacer-height-mobile: 80px !default;
.navbar-page-spacer {
	height: $page-spacer-height;

	@media (max-width: #{$small-display-size-width}px), (max-height: #{$small-display-size-height}px) {
		height: $page-spacer-height-mobile;
	}

	@media (max-height: #{$mobile-height-sticky-navbar}px) {
		height: $page-spacer-height-mobile;
	}
}

.nav-item-hidden-desktop {
	display: none;

	@media (max-width: #{$small-display-size-width}px), (max-height: #{$small-display-size-height}px) {
		display: block;
	}

	@media (max-height: #{$mobile-height-sticky-navbar}px) {
		display: block;
	}
}
.nav-item-hidden-desktop-h {
	display: none;

	@media (max-height: #{$small-display-size-height}px) {
		display: block;
	}

	@media (max-height: #{$mobile-height-sticky-navbar}px) {
		display: block;
	}
}
.nav-item-hidden-desktop-w {
	display: none;

	@media (max-width: #{$small-display-size-width}px) {
		display: block;
	}
}
