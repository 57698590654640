$primary: black;
@if variable-exists(active-color) {
	$primary: $active-color;
}

@import "~bootstrap/scss/bootstrap";

//$fa-font-path: "/lib/font-awesome/5.15.1/webfonts";
@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/duotone";

@import "bootstrap-extensions/bootstrapextend";

/*!
 * Global style.
 */

$active-color-alt1: black !default;
$active-color-alt2: black !default;

@import "includes/utilities";

html {
	position: relative;
	min-height: 100%;
}

body {
	overflow-y: scroll;
}

.rc-anchor-invisible-text {
	background-color: $primary !important;
}

.pointer-cursor {
	cursor: pointer;
}

@import "includes/fonts";

.slogan {
	font-family: "Brush Tip Terrence", "Helvetica Neue", Helvetica, Arial, sans-serif;
	color: $primary;
}

@import "includes/navbar";
@import "includes/jumbotron";
@import "includes/text";
@import "includes/carousel";
@import "includes/badges";
@import "includes/buttons";
@import "includes/form";
@import "includes/call-to-action";
@import "includes/timeline";
