.content {
  background-color: $content-bg;
  margin-top: 30px;
  border-radius: 0.25rem;
  .content-title {
    border-bottom: 1px solid $content-title-border-color;

    padding: 30px 15px;
    @include media-breakpoint-up(sm) {
      padding: 55px 60px;
    }

    position: relative;
    h1 {
      margin: 0;
    }
    &::before {
      border: 12px solid transparent;
      border-top-color: $content-title-border-color;
      bottom: 0;
      content: "";
      display: block;
      left: 10%;
      margin-bottom: -24px;
      position: absolute;
      z-index: 10;
    }
    &::after {
      border: 11px solid transparent;
      border-top-color: $content-bg;
      bottom: 0;
      content: "";
      display: block;
      left: 10%;
      margin-bottom: -21px;
      margin-left: 1px;
      position: absolute;
      z-index: 11;
    }
  }
  .content-body {
    padding: 30px 15px;
    @include media-breakpoint-up(sm) {
      padding: 55px 60px;
    }
  }
}
