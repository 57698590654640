.shopcrate-course-fullscreen {
  --sc-course-content-background-color: #e5d9cb;
  --sc-course-sidebar-background-color: #e5d9cb;
  --sc-course-header-background-color: #273f57;
  --sc-course-header-color: #e5d9cb;

  --sc-course-primary-background-color: #470023;
  --sc-course-primary-background-color-hover: #{mix(black, #470023, 15%)};
  --sc-course-primary-color: white;
  --sc-course-border-color: #{mix(black, #e5d9cb, 5%)};
  --sc-course-chapter-background-color: #{mix(black, #e5d9cb, 10%)};
  --sc-course-chapter-color: #273f57;
  --sc-course-lesson-color: #273f57;
  --sc-course-lesson-hover-background-color: #{mix(black, #e5d9cb, 10%)};
  --sc-course-lesson-hover-color: #273f57;
  --sc-course-lesson-complete-color: #bb2458;
}

.card-course {
  --sc-course-progress-text-color-0: #28a745;
  --sc-course-progress-text-color-50: #28a745;
  --sc-course-progress-text-color-100: #28a745;
}
