.navbar {
  &.bg-dark {
    background-color: $navbar-background-color !important;
  }
}

.navbar-dark {
  padding-top: 0;
  padding-bottom: 0;
  .navbar-toggler {
    @include media-breakpoint-down(md) {
      display: flex;
    }
    flex-direction: row;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 1.0rem;
    &:not(.collapsed) {
      color: white !important;
    }
    .navbar-toggler-fa {
      font-size: 1.5rem;
      margin-right: 4px;
    }
  }
  .navbar-nav {
    position: relative;
    .nav-link {
      position: relative;
      color: $navbar-inactive-color;

      font-size: .85em;
      font-weight: 500;
      letter-spacing: 1px;

      padding-top: 20px;
      padding-bottom: 20px;

      padding-left: 15px;
      padding-right: 15px;
      @include media-breakpoint-up(xl) {
        padding-left: 20px;
        padding-right: 20px;
      }
      @include media-breakpoint-down(md) {
        border-top: 1px solid rgba(255,255,255, .1);
        text-align: center;
      }
    }
    .nav-item {
      display: flex;
      align-items: center;
      text-align: center;
      .nav-link {
        width: 100%;
      }
    }
    @include media-breakpoint-up(lg) {
      &.navbar-separators {
        .nav-item {
          position: relative;
          &:not(:last-child)::before {
            color: $navbar-separator-color;
            font: var(--fa-font-solid);
            content: "\2f";
            display: block;
            font-size: 12px;
            font-weight: 300;
            margin-right: -3px;
            margin-top: -9px;
            position: absolute;
            right: 0;
            top: 50%;
            z-index: 1000;
          }
        }
      }
    }
  }
}
