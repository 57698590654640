.p-last-mb-0 {
  :last-child {
    margin-bottom: 0;
  }
}

.markdown {
  a {
    text-decoration: underline;
  }
}
